import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';

export default function Select(props) {

    const { name, label, noLabel, colorLabel, value, error=null, allOption, onChange, options, ...rest } = props;

    return (
        <FormControl
        {...(error && {error:true})}>
            {!noLabel && <InputLabel style={{color: colorLabel ? colorLabel : 'primary'}}>{label}</InputLabel>}
            <MuiSelect
                label={label}
                name={name}
                value={value && options.length>0 ? value : ""}
                onChange={onChange}
                {...rest}
                >
                <MenuItem value="">Sem dados</MenuItem>
                {allOption && options.length > 0 && <MenuItem value="todos">SELECIONAR TODOS</MenuItem>}
                {
                    options.map(
                        item => (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
