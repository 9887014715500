/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { lightTheme, darktheme } from '../styles/themes';

type Theme = 'light' | 'dark';

type ThemeContextType = {
  theme: Theme;
  toogleTheme(): void;
};

export const ThemeContext = createContext<ThemeContextType>(
  {} as ThemeContextType,
);

const ThemeAppProvider: React.FC = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(() => {
    const themeStorage = localStorage.getItem('@IEQLagoinhaTelao:theme');
    return (themeStorage ?? 'light') as Theme;
  });

  useEffect(() => {
    localStorage.setItem('@IEQLagoinhaTelao:theme', currentTheme);
  }, [currentTheme]);

  const toogleTheme = useCallback(() => {
    setCurrentTheme(currentTheme === 'light' ? 'dark' : 'light');
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, toogleTheme }}>
      <ThemeProvider theme={currentTheme === 'light' ? lightTheme : darktheme}>
        {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

function useTheme(): ThemeContextType {
  const context = useContext(ThemeContext);

  return context;
}

export { ThemeAppProvider, useTheme };
