import React from 'react';

import { SocketProvider } from './socket';
import { ToastProvider } from './toast';
import { ThemeAppProvider } from './theme';
import { LiveProvider } from './live';

const AppProvider: React.FC = ({ children }) => (
  <ThemeAppProvider>
    <SocketProvider>
      <LiveProvider>
        <ToastProvider>{children}</ToastProvider>
      </LiveProvider>
    </SocketProvider>
  </ThemeAppProvider>
);

export default AppProvider;
