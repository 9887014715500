import { Color, createColor } from 'material-ui-color';
import React, { createContext, useContext, useEffect, useState } from 'react';

type LiveContextType = {
  imagemFundo: boolean;
  screen: boolean;
  screenActivated: boolean;
  alertActived: boolean | undefined;
  alertText: string | undefined;
  alertBackgroundColor1: Color;
  alertBackgroundColor2: Color;
  alertForegroundColor: Color;
  libras: boolean;
  setAlertText: React.Dispatch<React.SetStateAction<string | undefined>>;
  setImagemFundo: React.Dispatch<React.SetStateAction<boolean>>;
  setScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertBackgroundColor1: React.Dispatch<React.SetStateAction<Color>>;
  setAlertBackgroundColor2: React.Dispatch<React.SetStateAction<Color>>;
  setAlertForegroundColor: React.Dispatch<React.SetStateAction<Color>>;
  setAlertActived: React.Dispatch<React.SetStateAction<boolean>>;
  setScreenActivated: React.Dispatch<React.SetStateAction<boolean>>;
  setLibras: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LiveContext = createContext<LiveContextType>(
  {} as LiveContextType,
);

const LiveProvider: React.FC = ({ children }) => {
  const [screen, setScreen] = useState<boolean>(false);
  const [imagemFundo, setImagemFundo] = useState<boolean>(false);
  const [alertActived, setAlertActived] = useState<boolean>(false);
  const [screenActivated, setScreenActivated] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>();
  const [libras, setLibras] = useState<boolean>(false);
  const [alertBackgroundColor1, setAlertBackgroundColor1] = useState<Color>(
    () => {
      const backgroundStorage = localStorage.getItem(
        '@IEQLagoinhaTelao:background1',
      );
      return backgroundStorage
        ? createColor(Number(backgroundStorage))
        : createColor('red');
    },
  );
  const [alertBackgroundColor2, setAlertBackgroundColor2] = useState<Color>(
    () => {
      const backgroundStorage = localStorage.getItem(
        '@IEQLagoinhaTelao:background2',
      );
      return backgroundStorage
        ? createColor(Number(backgroundStorage))
        : createColor('red');
    },
  );
  const [alertForegroundColor, setAlertForegroundColor] = useState<Color>(
    () => {
      const foregroundStorage = localStorage.getItem(
        '@IEQLagoinhaTelao:foreground',
      );
      return foregroundStorage
        ? createColor(Number(foregroundStorage))
        : createColor('white');
    },
  );

  useEffect(() => {
    localStorage.setItem(
      '@IEQLagoinhaTelao:background1',
      alertBackgroundColor1.value.toString(),
    );
  }, [alertBackgroundColor1]);

  useEffect(() => {
    localStorage.setItem(
      '@IEQLagoinhaTelao:background2',
      alertBackgroundColor2.value.toString(),
    );
  }, [alertBackgroundColor2]);

  useEffect(() => {
    localStorage.setItem(
      '@IEQLagoinhaTelao:foreground',
      alertForegroundColor.value.toString(),
    );
  }, [alertForegroundColor]);

  return (
    <LiveContext.Provider
      value={{
        alertActived,
        imagemFundo,
        screenActivated,
        alertText,
        setAlertText,
        alertBackgroundColor1,
        alertBackgroundColor2,
        alertForegroundColor,
        setAlertBackgroundColor1,
        setAlertBackgroundColor2,
        setAlertForegroundColor,
        libras,
        setScreen,
        setImagemFundo,
        screen,
        setAlertActived,
        setScreenActivated,
        setLibras,
      }}
    >
      {children}
    </LiveContext.Provider>
  );
};

function useLive(): LiveContextType {
  const context = useContext(LiveContext);

  return context;
}

export { LiveProvider, useLive };
