import styled, { keyframes } from 'styled-components';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { shade } from 'polished';

import signInBackgroundImg from '../../assets/imagem_fundo.png';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

export const ShareScreen = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;

  > p {
    margin-left: 7px;
    margin-right: 20px;
  }
`;

export const Compartilhamento = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 600px;

  button + button {
    margin-left: 25px;
  }
`;

export const Broadcast = styled.div`
  position: absolute;
  z-index: 10;

  @media screen and (max-width: 767px) {
    right: 40px;
    top: 170px;
  }

  @media screen and (min-width: 768px) {
    right: 20px;
    top: 90px;
  }

  > svg {
    font-size: 2.5em;
    color: darkgreen;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 30px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
      color: #000000;
    }

    a {
      color: #000000;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#000000')};
      }
    }
  }

  > a {
    color: #000000;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#000000')};
    }
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > p {
      margin-left: 10px;
      font-style: italic;
    }

    > span {
      margin-left: 10px;
      font-weight: bold;
    }
  }
`;

export const SharedScreenLive = styled.video`
  width: 350px;
`;

export const Controle = styled.div`
  display: flex;
  height: 70px;
  justify-content: stretch;
  align-items: stretch;
  margin-bottom: 30px;

  > button {
    width: 100%;
    height: 100%;
    color: darkgreen;
    font-size: 24px;
    font-weight: bold;
  }
`;

export const AlertColorGradiente = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 50px;

  > p {
    font-size: 20px;
    font-weight: bold;
    color: darkgreen;
  }
`;

export const Gradiente = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  > div {
    width: 100%;
    margin-left: 10px;
    > div {
      > div {
        > input {
          margin-left: 10px;
          font-size: 20px;
        }
      }
    }
  }

  span {
    > div {
      border: 2px solid black;
    }
  }
`;

export const OnOffDestaque = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 5px;

  span {
    font-size: 28px;
    font-weight: bold;
    color: red;
  }
`;

export const ConfigAlert = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 2px solid red;
  border-radius: 10px;

  > p {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 20px;
    color: red;
  }
`;

export const AlertColor = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 50px;

  > p {
    font-size: 20px;
    font-weight: bold;
    color: darkgreen;
  }

  > div {
    width: 100%;
    margin-left: 10px;
    > div {
      > div {
        > input {
          margin-left: 10px;
          font-size: 20px;
        }
      }
    }
  }

  span {
    > div {
      border: 2px solid black;
    }
  }
`;

export const OnOffNormal = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 5px;

  span {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    color: blue;
  }
`;

export const ScreenLive = styled.video`
  width: 350px;
  height: 200px;
  border: 1px solid black;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
  opacity: 0.5;
`;

export const useStyles = makeStyles((theme: Theme) => ({
  selectForm: {
    width: '80%',
    margin: 20,
  },
  inputForm: {
    width: '100%',
    marginTop: 30,
  },
  buttonForm: {
    color: theme.palette.primary.contrastText,
    margin: 20,
  },
}));
