import styled, { css } from 'styled-components';
import Webcam from 'react-webcam';

interface AlertLiveProps {
  corTexto: string;
  corFundo1: string;
  corFundo2: string;
  widthScreen: string;
  libras?: boolean;
}

interface WebcamProps {
  hidden?: boolean;
}

interface ImagemFundoProps {
  hidden?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
`;

export const Compartilhamento = styled.div`
  display: flex;
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 90;
`;

export const ImagemFundo = styled.div<ImagemFundoProps>`
  position: absolute;
  background-size: cover;
  opacity: 0.4;
  z-index: 95;
  visibility: visible;
  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}
`;

export const FrenteImagem = styled.div<ImagemFundoProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 96;
  visibility: visible;
  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}

  > p {
    color: #000;
    font-size: 60px;
    font-weight: bold;
    font-family: arial;
  }
`;

export const Imagem = styled.img``;

export const WebcamLive = styled(Webcam)<WebcamProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  z-index: 80;
  visibility: visible;
  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}
`;

export const SharedScreenLive = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  z-index: 60;
`;

export const AlertLive = styled.div<AlertLiveProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  min-height: 100px;

  ${({ libras }) =>
    libras &&
    css`
      width: 80%;
    `}

  ${({ corFundo1, corFundo2 }) =>
    corFundo1 &&
    corFundo2 &&
    css`
      background-image: linear-gradient(
        to bottom right,
        ${corFundo1},
        ${corFundo2}
      );
    `}

  z-index: 100;
  position: absolute;
  bottom: 0;

  left: 0px;
  bottom: 0px;
  animation: myalert 5s;
  -moz-animation: myalert 5s; /* Firefox */
  -webkit-animation: myalert 5s; /* Safari and Chrome */

  > p {
    color: ${props => props.corTexto};
    font-family: Verdana, sans-serif;
    font-size: 50px;
    opacity: 1;
    animation-name: mytextalertblink;
    animation-duration: 1s;
    animation-iteration-count: 10;
    animation-delay: 15s;
    -moz-animation-name: mytextalertblink;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: 10;
    -moz-animation-delay: 15s;
    -webkit-animation-name: mytextalertblink;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: 10;
    -webkit-animation-delay: 15s;
  }

  @keyframes myalert {
    from {
      left: -${props => props.widthScreen};
    }
    to {
      left: 0px;
    }
  }

  @-moz-keyframes myalert {
    from {
      left: -${props => props.widthScreen};
    }
    to {
      left: 0px;
    }
  }

  @-webkit-keyframes myalert {
    from {
      left: -${props => props.widthScreen};
    }
    to {
      left: 0px;
    }
  }

  @keyframes mytextalertblink {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-moz-keyframes mytextalertblink {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes mytextalertblink {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
