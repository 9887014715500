import React from 'react';
import { Header } from '..';

import { useStyles } from '../../App-styles';

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <Header />
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
