import React, { useEffect, useState } from 'react';
import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { Color, ColorPicker, createColor } from 'material-ui-color';
import SyncIcon from '@material-ui/icons/Sync';
import Layout from '../../components/Layout';
import {
  useStyles,
  Container,
  Controle,
  OnOffDestaque,
  OnOffNormal,
  AlertColor,
  ConfigAlert,
  Broadcast,
  Gradiente,
  AlertColorGradiente,
} from './styles';
import Controls from '../../components/controls/Controls.js';
import { useLive } from '../../hooks/live';
import { useSocket } from '../../hooks/socket';

interface Dispositivos {
  id: string;
  title: string;
}

interface Windows {
  id: string;
  title: string;
}

interface AtualizaTelao {
  socketId: string;
  libras: boolean;
  textoAlerta: string;
  corFundo1: Color;
  corFundo2: Color;
  corFonte: Color;
  alerta: boolean;
  screenLoaded: boolean;
  screenActivated: boolean;
  fundo: boolean;
}

interface AtualizaControle {
  socketId: string;
  libras: boolean;
  textoAlerta: string;
  corFundo1: string;
  corFundo2: string;
  corFonte: string;
  alerta: boolean;
  screenLoaded: boolean;
  screenActivated: boolean;
  fundo: boolean;
}

interface SocketMensagem {
  id: string;
  atualizacao: string;
}

const MainPage: React.FC = () => {
  const { conectarSocket, socket } = useSocket();
  const {
    setAlertText,
    alertBackgroundColor1,
    alertBackgroundColor2,
    alertForegroundColor,
    setAlertBackgroundColor1,
    setAlertBackgroundColor2,
    setAlertForegroundColor,
    setScreen,
    alertText,
    alertActived,
    screenActivated: sharedActivated,
    libras,
    setAlertActived,
    setScreenActivated,
    setLibras,
    screen,
    imagemFundo,
    setImagemFundo,
  } = useLive();
  const classes = useStyles();
  const [atualizacaoControles, setAtualizacaoControles] =
    useState<boolean>(false);
  const [novaConexao, setNovaConexao] = useState<boolean>(true);

  useEffect(() => {
    conectarSocket('tasilva@gmail.com');
  }, [conectarSocket]);

  useEffect(() => {
    if (socket) {
      socket.on('controles', (data: SocketMensagem) => {
        console.log('[SOCKET] Recebi Atualização Controles');
        setAtualizacaoControles(true);
        console.log(data);
        const { atualizacao } = data;
        const atualizacaoControle = JSON.parse(atualizacao) as AtualizaControle;
        const {
          libras: libraONOFF,
          textoAlerta,
          corFundo1,
          corFundo2,
          corFonte,
          alerta,
          screenLoaded,
          screenActivated,
          fundo,
        } = atualizacaoControle;
        setScreen(screenLoaded);
        setScreenActivated(screenActivated);
        setAlertText(textoAlerta);
        setImagemFundo(fundo);
        setAlertBackgroundColor1(createColor(Number(corFundo1)));
        setAlertBackgroundColor2(createColor(Number(corFundo2)));
        setAlertForegroundColor(createColor(Number(corFonte)));
        setLibras(libraONOFF);
        setAlertActived(alerta);
        setAtualizacaoControles(false);
      });
      socket.on('iniciais', (data: SocketMensagem) => {
        console.log('[SOCKET] Recebi Atualização Inicial dos Controles');
        setAtualizacaoControles(true);
        try {
          const { atualizacao } = data;
          const atualizacaoControle = JSON.parse(
            atualizacao,
          ) as AtualizaControle;
          const {
            libras: libraONOFF,
            textoAlerta,
            corFundo1,
            corFundo2,
            corFonte,
            alerta,
            screenLoaded,
            screenActivated,
            fundo,
          } = atualizacaoControle;
          try {
            setScreen(screenLoaded);
            setScreenActivated(screenActivated);
            setAlertText(textoAlerta);
            setImagemFundo(fundo);
            setAlertBackgroundColor1(createColor(Number(corFundo1)));
            setAlertBackgroundColor2(createColor(Number(corFundo2)));
            setAlertForegroundColor(createColor(Number(corFonte)));
            setLibras(libraONOFF);
            setAlertActived(alerta);
          } catch {
            console.log('Não foi possível trazer os controles iniciais');
          }
        } catch {
          console.log('Não foi possível trazer os controles iniciais');
        }
        setAtualizacaoControles(false);
        setNovaConexao(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on('configuracao', () => {
        console.log('[SOCKET] Recebi Solicitação de Configurar os Controles');
        const atualizacaoControle = {
          alerta: alertActived,
          corFonte: alertForegroundColor.value.toString(),
          corFundo1: alertBackgroundColor1.value.toString(),
          corFundo2: alertBackgroundColor2.value.toString(),
          libras,
          socketId: socket.id,
          textoAlerta: alertText,
          screenActivated: sharedActivated,
          screenLoaded: screen,
          fundo: imagemFundo,
          atualizacao: true,
        } as AtualizaControle;
        socket.emit('controles', atualizacaoControle);
        setNovaConexao(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const syncUpdates = (): void => {
    if (socket) {
      if (!atualizacaoControles && !novaConexao) {
        const atualizacao = {
          alerta: alertActived,
          corFonte: alertForegroundColor,
          corFundo1: alertBackgroundColor1,
          corFundo2: alertBackgroundColor2,
          libras,
          socketId: socket.id,
          textoAlerta: alertText,
          screenActivated: sharedActivated,
          screenLoaded: screen,
          fundo: imagemFundo,
        } as AtualizaTelao;
        const messageTelao = {
          id: socket.id,
          atualizacao: JSON.stringify(atualizacao),
        };
        socket.emit('telao', messageTelao);

        const atualizacaoControle = {
          alerta: alertActived,
          corFonte: alertForegroundColor.value.toString(),
          corFundo1: alertBackgroundColor1.value.toString(),
          corFundo2: alertBackgroundColor2.value.toString(),
          libras,
          socketId: socket.id,
          textoAlerta: alertText,
          screenActivated: sharedActivated,
          screenLoaded: screen,
          atualizacao: true,
          fundo: imagemFundo,
        } as AtualizaControle;
        const messageControle = {
          id: socket.id,
          atualizacao: JSON.stringify(atualizacaoControle),
        };
        socket.emit('controles', messageControle);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleAlertChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setAlertActived(event.target.checked);
  };

  const handleLibrasChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setLibras(event.target.checked);
  };

  const handleFundoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setImagemFundo(event.target.checked);
  };

  const handleScreenLoadChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setScreen(event.target.checked);
  };

  const handleScreenActivateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setScreenActivated(event.target.checked);
  };

  const abrirLive = (): void => {
    const wihe = `width=${window.screen.availWidth},height=${window.screen.availHeight}`;
    const caminho = `${window.location}live`;
    window.open(
      caminho,
      '_blank',
      `left=1,top=1,location=no,menubar=no,titlebar=0,toolbar=no,scrollbars=no,fullscreen=yes,${wihe}`,
    );
  };

  const handleBackgroundColor1Change = (newValue: Color): void => {
    setAlertBackgroundColor1(newValue);
  };

  const handleBackgroundColor2Change = (newValue: Color): void => {
    setAlertBackgroundColor2(newValue);
  };

  const handleForegroundColorChange = (newValue: Color): void => {
    setAlertForegroundColor(newValue);
  };

  return (
    <Layout>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Controle>
              <Controls.Button
                type="button"
                text="Abrir Tela de Live"
                onClick={abrirLive}
              />
            </Controle>
            <OnOffDestaque>
              <FormControlLabel
                control={
                  <Switch checked={alertActived} onChange={handleAlertChange} />
                }
                label="Alerta"
              />
            </OnOffDestaque>
            <OnOffNormal>
              <FormControlLabel
                control={
                  <Switch checked={libras} onChange={handleLibrasChange} />
                }
                label="Libras"
              />
            </OnOffNormal>
            <OnOffNormal>
              <FormControlLabel
                control={
                  <Switch checked={imagemFundo} onChange={handleFundoChange} />
                }
                label="Tela de fundo"
              />
            </OnOffNormal>
            <OnOffNormal>
              <FormControlLabel
                control={
                  <Switch checked={screen} onChange={handleScreenLoadChange} />
                }
                label="Carregar compartilhamento tela"
              />
            </OnOffNormal>
            <OnOffNormal>
              <FormControlLabel
                control={(
                  <Switch
                    checked={sharedActivated}
                    onChange={handleScreenActivateChange}
                  />
                )}
                label="Visualizar tela compartilhada"
              />
            </OnOffNormal>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ConfigAlert>
              <p>Tarja alerta</p>
              <AlertColorGradiente>
                <p>Fundo</p>
                <Gradiente>
                  <ColorPicker
                    value={alertBackgroundColor1}
                    onChange={handleBackgroundColor1Change}
                  />
                  <ColorPicker
                    value={alertBackgroundColor2}
                    onChange={handleBackgroundColor2Change}
                  />
                </Gradiente>
              </AlertColorGradiente>
              <AlertColor>
                <p>Fonte</p>
                <ColorPicker
                  value={alertForegroundColor}
                  onChange={handleForegroundColorChange}
                />
              </AlertColor>
            </ConfigAlert>
            <TextField
              name="texto"
              label="Texto para alerta"
              multiline
              value={alertText}
              onChange={e => {
                setAlertText(e.target.value);
              }}
              className={classes.inputForm}
            />
          </Grid>
        </Grid>
        <Broadcast onClick={syncUpdates}>
          <SyncIcon />
        </Broadcast>
      </Container>
    </Layout>
  );
};

export { MainPage };
