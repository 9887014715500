import { AppBar, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import logoImg from '../../assets/lagoinha_preto.png';

export function Header(): any {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <img
          src={logoImg}
          alt="IEQ Lagoinha"
          height="45px"
          className={classes.imageHeader}
        />
        <Typography variant="h6" color="inherit" className={classes.title}>
          Sistema de interação do Telão
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
