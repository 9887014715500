import React from 'react'
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AutoComplete(props) {

    const { name, label, value, error=null, onChange, options, ...rest } = props;

    const val = {
      id:"0",
      title:""
    }

    let errorShow=error;

    const optionsNovo = [...options,val];

    let valueFinded = value;
    if (value && value.id!==""){
      valueFinded = optionsNovo.find(v => v.id===value.id);
    }

    return (
      <Autocomplete
        id={name}
        name={name}
        options={optionsNovo}
        value={valueFinded && valueFinded.id!=="" ? valueFinded : val}
        noOptionsText="Sem opções"
        getOptionLabel={(option) => (option.title ? option.title : "")}
        getOptionSelected={(option, value) => (value && value.id!=="") ? option.id===value.id : false}
        {...rest}
        renderInput={params => (
          <TextField {...params} label={label} variant="outlined" helperText={errorShow} {...(errorShow && {error:true})} />
        )}
        onChange={(e, v, r) => {
          if (v){
            onChange(name, v.id)
          }
        }}
      />
    );
}
