import React, { createContext, useCallback, useContext, useState } from 'react';
import socketio from 'socket.io-client';

interface ISocketContextData {
  socket?: SocketIOClient.Socket;
  conectarSocket(userId: string): void;
  desconectarSocket(): void;
}

const SocketContext = createContext<ISocketContextData>(
  {} as ISocketContextData,
);

const SocketProvider: React.FC = ({ children }) => {
  const [socketIO, setSocketIO] = useState<SocketIOClient.Socket>();

  const conectarSocket = useCallback((userId: string) => {
    if (userId) {
      try {
        let socket: SocketIOClient.Socket;
        if (process.env.REACT_APP_NODE_ENV === 'development') {
          socket = socketio.connect(`${process.env.REACT_APP_SOCKET_API_URL}`);
        } else {
          socket = socketio.connect(`${process.env.REACT_APP_SOCKET_API_URL}`, {
            path: '/ws/',
          });
        }

        socket.on('connect_error', (err: any) => {
          console.log('Erro Socket');
        });

        socket.on('connect', () => {
          setSocketIO(socket);
          console.log(
            '[IO] Connect API => A new connection has been established',
          );
          socket.emit('usuario', userId);
        });
      } catch {
        console.log('Erro Socket');
      }
    }
  }, []);

  const desconectarSocket = useCallback(() => {
    if (socketIO) {
      socketIO.emit('kick', socketIO.id);
    }
  }, [socketIO]);

  return (
    <SocketContext.Provider
      value={{
        socket: socketIO,
        conectarSocket,
        desconectarSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

function useSocket(): ISocketContextData {
  const context = useContext(SocketContext);

  return context;
}

export { SocketProvider, useSocket };
