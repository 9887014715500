import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import GlobalStyle from './styles/global';

import AppProvider from './hooks';

import { MainPage } from './pages/MainPage';
import { LivePage } from './pages/LivePage';

const App: React.FC = () => (
  <BrowserRouter>
    <AppProvider>
      <Switch>
        <Route path="/" exact component={MainPage} />
        <Route path="/live" exact component={LivePage} />
      </Switch>
      <GlobalStyle />
    </AppProvider>
  </BrowserRouter>
);

export default App;
