import React, { useEffect, useRef } from 'react';
import { Color } from 'material-ui-color';
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import { useLive } from '../../hooks/live';
import {
  Container,
  WebcamLive,
  AlertLive,
  SharedScreenLive,
  Compartilhamento,
  ImagemFundo,
  Imagem,
  FrenteImagem,
} from './styles';
import { useSocket } from '../../hooks/socket';
import imagem_fundo from '../../assets/imagem_fundo.png';
import logoIMG from '../../assets/lagoinha_preto.png';

interface AtualizaTelao {
  socketId: string;
  libras: boolean;
  textoAlerta: string;
  corFundo1: Color;
  corFundo2: Color;
  corFonte: Color;
  alerta: boolean;
  screenLoaded: boolean;
  screenActivated: boolean;
  fundo: boolean;
}

interface SocketMensagem {
  id: string;
  atualizacao: string;
}

const LivePage: React.FC = () => {
  const {
    alertActived,
    alertText,
    alertBackgroundColor1,
    alertBackgroundColor2,
    alertForegroundColor,
    libras,
    setAlertText,
    screen,
    screenActivated: sharedActivated,
    setAlertActived,
    setAlertBackgroundColor1,
    setAlertBackgroundColor2,
    setAlertForegroundColor,
    setLibras,
    setScreen,
    setScreenActivated,
    imagemFundo,
    setImagemFundo,
  } = useLive();
  const { conectarSocket, socket } = useSocket();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    conectarSocket('tasilva@gmail.com');
  }, [conectarSocket]);

  useEffect(() => {
    if (socket) {
      socket.on('telao', (data: SocketMensagem) => {
        console.log('[SOCKET] Recebi Atualização Telão');
        const { atualizacao } = data;
        const atualizacaoTelao = JSON.parse(atualizacao) as AtualizaTelao;
        const {
          libras: libraONOFF,
          textoAlerta,
          corFundo1,
          corFundo2,
          corFonte,
          alerta,
          screenLoaded,
          screenActivated,
          fundo,
        } = atualizacaoTelao;
        setScreen(screenLoaded);
        setScreenActivated(screenActivated);
        setAlertText(textoAlerta);
        setImagemFundo(fundo);
        setAlertBackgroundColor1(corFundo1);
        setAlertBackgroundColor2(corFundo2);
        setAlertForegroundColor(corFonte);
        setLibras(libraONOFF);
        setAlertActived(alerta);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const shareScreen = async (): Promise<void> => {
    const opts = {
      audio: false,
      video: true,
      width: { ideal: 1920 },
      height: { ideal: 1080 },
    };
    const stream = await navigator.mediaDevices.getDisplayMedia(opts);
    if (videoRef.current !== null) {
      videoRef.current.srcObject = stream;
      if (sharedActivated) {
        videoRef.current.hidden = false;
        videoRef.current.play();
      }
    }
  };

  useEffect(() => {
    if (videoRef.current !== null && !screen) {
      videoRef.current.hidden = true;
      videoRef.current.srcObject = null;
    }
  }, [screen]);

  useEffect(() => {
    if (
      videoRef.current !== null &&
      screen &&
      sharedActivated &&
      !imagemFundo
    ) {
      try {
        videoRef.current.hidden = false;
        videoRef.current.play();
      } catch {
        console.log('Erro ao dar play');
      }
    } else if (
      videoRef.current !== null &&
      screen &&
      (!sharedActivated || imagemFundo)
    ) {
      try {
        videoRef.current.pause();
        videoRef.current.hidden = true;
      } catch {
        console.log('Erro ao parar');
      }
    }
  }, [screen, sharedActivated, imagemFundo]);

  useEffect(() => {
    if (
      videoRef &&
      videoRef.current !== null &&
      (!screen || !sharedActivated || imagemFundo)
    ) {
      videoRef.current.hidden = true;
    }
  }, [videoRef, screen, sharedActivated, imagemFundo]);

  return (
    <>
      <Container>
        <ImagemFundo hidden={!imagemFundo}>
          <Imagem src={imagem_fundo} alt="IEQ Lagoinha" />
        </ImagemFundo>
        <FrenteImagem hidden={!imagemFundo}>
          <img src={logoIMG} alt="IEQ Lagoinha" />
          <p>Apaixonada por almas</p>
          <p>Apaixonada pela Presença de Deus</p>
        </FrenteImagem>
        <WebcamLive
          hidden={sharedActivated || imagemFundo}
          audio={false}
          videoConstraints={{
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            frameRate: { ideal: 60 },
          }}
        />
        <SharedScreenLive ref={videoRef} />
        {alertActived && (
          <AlertLive
            corTexto={`#${alertForegroundColor.hex}`}
            corFundo1={`#${alertBackgroundColor1.hex}`}
            corFundo2={`#${alertBackgroundColor2.hex}`}
            widthScreen={`${window.screen.availWidth}px`}
            libras={libras}
          >
            <p>{alertText}</p>
          </AlertLive>
        )}
        {screen && (
          <Compartilhamento>
            <ScreenShareOutlinedIcon onClick={shareScreen} />
          </Compartilhamento>
        )}
      </Container>
    </>
  );
};

export { LivePage };
